import { Button, Box, Divider, useMediaQuery } from '@mui/material';
import Person4Icon from '@mui/icons-material/Person4';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchIcon from '@mui/icons-material/Search';

const FilterBar = (
    {
        artistProfileSelectedLabel,
        countryCode,
        provinceCode,
        city,
        zone,
        musicStyle,
        period,
        formattedDate,
        handleOpenSearch
    }
) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: isMobile ? 'center' : 'flex-start',
                    flexDirection: isMobile ? 'column' : 'row',
                    mt: 2,
                    mb: 6,
                    width: '100%',
                    bgcolor: '#614ed9',
                    p: 0.5,
                    color: 'white',
                    borderRadius: 2,
                    flexWrap: isMobile ? 'wrap' : 'nowrpa',
                    gap: 1,
                }}
            >

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: isMobile ? 'wrap' : 'nowrpa',
                        gap: 1,
                        '& > *': {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: isMobile ? '15ch' : 'none',
                        },
                        ml: 1
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <Person4Icon /> {artistProfileSelectedLabel}</Box>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1, borderColor: 'grey.300', borderWidth: '0.1px' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <LocationOnIcon />
                        {countryCode}, {provinceCode},
                        {city},
                        {zone}
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1, borderColor: 'grey.300', borderWidth: '0.1px' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MusicNoteIcon /> {musicStyle}
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1, borderColor: 'grey.300', borderWidth: '0.1px' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTimeIcon /> {period}
                    </Box>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1, borderColor: 'grey.300', borderWidth: '0.1px' }} />
                    <Box sx={{ display: 'flex', alignItems: 'center' }}> <CalendarMonthIcon /> {formattedDate}</Box>
                </Box>

                {handleOpenSearch && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                            width: isMobile ? '100%' : 'auto',
                            mt: isMobile ? 2 : 0,
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                minWidth: '80px',
                                maxWidth: '120px',
                                backgroundColor: '#FFCA00',
                                color: 'white',
                                height: '50px',
                                '&:hover': {
                                    backgroundColor: '#FFCA00',
                                },
                                borderRadius: '12px',
                                fontWeight: 'bold',
                            }}
                            startIcon={<SearchIcon />}
                            onClick={handleOpenSearch}
                        >
                            Pesquisar
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
}

export default FilterBar;