import React from "react";
import { Box, Typography, Stack, useMediaQuery } from "@mui/material";

const WarningLogin = ({ title, description, highlightedText }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const renderDescription = () => {
        if (!highlightedText) return description;

        const parts = description.split(highlightedText);
        return (
            <>
                {parts.map((part, index) => (
                    <React.Fragment key={index}>
                        {part}
                        {index < parts.length - 1 && (
                            <Typography
                                component="span"
                                sx={{ color: "#4F46E5", fontWeight: "bold" }}
                            >
                                {highlightedText}
                            </Typography>
                        )}
                    </React.Fragment>
                ))}
            </>
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                maxHeight: isMobile ? '380px' : '350px',
                mt: isMobile ? 8 : 10,
                ml: isMobile ? 5 : 5,
                mr: isMobile ? 5 : 5,
                textAlign: isMobile ? 'center' : 'left',
            }}
        >
            <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                alignItems="center"
                sx={{
                    backgroundColor: "#f9f9f9",
                    padding: 2,
                    borderRadius: 2,
                }}
            >
                <Box
                    component="img"
                    src="/static/images/lock.webp"
                    alt="Lock Icon"
                    sx={{
                        width: isMobile ? 150 : 250,
                        height: isMobile ? 150 : 250,
                        marginBottom: isMobile ? 2 : 0,
                        marginRight: isMobile ? 0 : 2,
                    }}
                />

                <Stack alignItems={isMobile ? "center" : "flex-start"}>
                    <Typography variant="h6" fontWeight="bold">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {renderDescription()}
                    </Typography>
                </Stack>
            </Box>
        </Box>
    );
};

export default WarningLogin;
