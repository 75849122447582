import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Typography,
  IconButton,
  Chip,
  Box,
  Divider,
  Rating,
  useMediaQuery,
  Tooltip,
  ClickAwayListener,
  Grid,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';

const SearchResultCard = ({
  artist,
  artistProfileSelected,
  countryCode,
  provinceCode,
  city,
  zone,
  musicStyle,
  period,
  date
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [openTooltipTop5, setOpenTooltipTop5] = useState(false);

  const nextImage = () => {
    setCurrentImageIndex((prev) =>
      prev === artist.artist_pictures.length - 1 ? 0 : prev + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prev) =>
      prev === 0 ? artist.artist_pictures.length - 1 : prev - 1
    );
  };

  const getChipColor = (artistType) => {
    switch (artistType) {
      case 'band':
        return { bg: '#8C57FF', text: '#C6ACFF' };
      case 'musician':
        return { bg: '#16B1FF', text: '#0087C9' };
      case 'dj':
        return { bg: '#FFB400', text: '#9B6E00' };
      default:
        return { bg: '#CCCCCC', text: '#666666' };
    }
  };

  const handleTooltipTop5Close = () => {
    setOpenTooltipTop5(false);
  };

  const handleTooltipTop5Open = () => {
    setOpenTooltipTop5(true);
  };

  const StyledLocalActivityIcon = styled(LocalActivityIcon)(({ theme }) => ({
    position: 'absolute',
    top: 16,
    left: 16,
    zIndex: 1,
    color: '#FFB400',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
    },
  }));

  return (
    <>
      {!isMobile ? (
        <Card sx={{
          maxWidth: '4xl',
          width: '100%',
          boxShadow: 1,
          borderRadius: 2,
          mb: 5
        }}>
          <Box
            sx={{ display: 'flex' }}
          >
            {/* Left side - Image carousel */}
            <Box sx={{ position: 'relative', width: '33%' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mt: isMobile ? 5 : 0
                }}
              >
                {artist && artist.rating >= 4.8 && (
                  <>
                    {!isMobile ? (
                      <Tooltip title={
                        <Box>
                          <Typography fontWeight="bold">Pertence ao Top 5 artistas</Typography>
                          <Typography>Este artista se destaca por ter uma boa qualificação no oferecimento de serviços e comentários positivos.</Typography>
                        </Box>
                      }
                        arrow placement="bottom">

                        <StyledLocalActivityIcon fontSize="large" />
                      </Tooltip>
                    ) : (
                      <ClickAwayListener onClickAway={handleTooltipTop5Close}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipTop5Close}
                          open={openTooltipTop5}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={
                            <Box>
                              <Typography fontWeight="bold">Pertence ao Top 5 artistas</Typography>
                              <Typography>Este artista se destaca por ter uma boa qualificação no oferecimento de serviços e comentários positivos.</Typography>
                            </Box>
                          }

                        >
                          <IconButton
                            size="small"

                            onClick={handleTooltipTop5Open}
                          >
                            <StyledLocalActivityIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    )}
                  </>

                )}
              </Box>
              <Box sx={{ position: 'relative', height: 256 }}>
                <CardMedia
                  component="img"
                  height="256"
                  image={artist.artist_pictures[currentImageIndex] || "/api/placeholder/400/320"}
                  alt={`View ${currentImageIndex + 1}`}
                  sx={{ objectFit: 'cover' }}
                />
                <IconButton
                  onClick={prevImage}
                  sx={{
                    position: 'absolute',
                    left: 8,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    '&:hover': { backgroundColor: 'white' }
                  }}
                >
                  <ChevronLeft />
                </IconButton>
                <IconButton
                  onClick={nextImage}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    '&:hover': { backgroundColor: 'white' }
                  }}
                >
                  <ChevronRight />
                </IconButton>
              </Box>
            </Box>

            {/* Right side - Content */}
            <CardContent
              onClick={() => navigate(`/card/details/${artist.auth0_sub_id}/${artistProfileSelected}/${countryCode}/${provinceCode}/${city}/${zone}/${musicStyle}/${period}/${date}`)}
              sx={{
                flex: 1,
                p: 2,
                pt: 2,
                pl: 0,
                position: 'relative',
                borderLeft: '1px solid #e0e0e0',
                cursor: 'pointer'
              }}>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', pl: 2 }}>
                <Box>
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{
                      textAlign: 'left',
                      mb: 1,
                      padding: 0
                    }}
                  >
                    {artist.artist_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    {artist.country}, {artist.province}, {artist.city}, {artist.zone}
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 2 }}>
                    <Chip
                      label={
                        artist.artist_type === 'band' ? 'banda' :
                          artist.artist_type === 'musician' ? 'músico' :
                            artist.artist_type === 'dj' ? 'dj' :
                              artist.artist_type
                      }
                      sx={{
                        backgroundColor: getChipColor(artist.artist_type).bg,
                        color: getChipColor(artist.artist_type).text,
                        fontWeight: 'bold',
                        height: '24px',
                        fontSize: '0.75rem',
                        padding: '0 8px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '120px',
                      }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 0 }}>
                      <Rating sx={{ mr: 1 }} name="read-only" value={Math.floor(artist.rating)} readOnly />
                      <Typography>({artist.rating.toFixed(1)})</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Divider
                orientation="vertical"
                sx={{
                  position: 'absolute',
                  right: '210px',
                  height: '100%',
                  top: '0%',
                  bottom: '0%'
                }}
              />

              <Box sx={{ position: 'absolute', bottom: 186, right: 16, textAlign: 'right' }}>
                <Typography
                  variant="h6"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#2e263de6',
                    fontWeight: 'bold'
                  }}
                >
                  R$ {artist.price}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Impostos inclusos
                </Typography>
              </Box>

              <CardActions sx={{ position: 'absolute', bottom: 8, right: 8, justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#4F46E5',
                    '&:hover': {
                      backgroundColor: '#4338CA',

                    },
                    textTransform: 'capitalize',
                  }}
                >
                  Ver mais
                </Button>
              </CardActions>
            </CardContent>
          </Box>
        </Card>
      ) : (
        <>
          <Card
            sx={{
              width: isMobile ? '100%' : '320px',
              maxWidth: '100%',
              mb: 4
            }}
          >
            <Box sx={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box
                sx={{
                  mt: 0,
                  borderRadius: '10px',
                  overflow: 'hidden',
                  width: '95%',
                  maxWidth: 300,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {artist && artist.rating >= 4.8 && (
                    <>
                      <ClickAwayListener onClickAway={handleTooltipTop5Close}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipTop5Close}
                          open={openTooltipTop5}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={
                            <Box>
                              <Typography fontWeight="bold">Pertence ao Top 5 artistas</Typography>
                              <Typography>Este artista se destaca por ter uma boa qualificação no oferecimento de serviços e comentários positivos.</Typography>
                            </Box>
                          }

                        >
                          <IconButton
                            size="small"
                            onClick={handleTooltipTop5Open}
                          >
                            <StyledLocalActivityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ClickAwayListener>
                    </>

                  )}
                </Box>
                <CardMedia
                  sx={{
                    width: '100%',
                    height: 160,
                    transition: 'transform 0.3s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                    borderRadius: '10px 10px 0 0',
                  }}
                  image={artist.artist_pictures[currentImageIndex]}
                />

              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '-20px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&:active': {
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  },
                  transition: 'background-color 0.3s',
                  height: '50px',
                  width: '40px',
                  display: 'flex',
                  alignItems: 'right',
                  justifyContent: 'right',
                  borderRadius: '5px',
                }}
              >
                <IconButton
                  onClick={prevImage}
                  sx={{
                    padding: 0,
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  <ArrowBackIosNewIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
                </IconButton>
              </Box>

              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '-20px',
                  transform: 'translateY(-50%)',
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                  },
                  '&:active': {
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  },
                  transition: 'background-color 0.3s',
                  height: '50px',
                  width: '40px',
                  display: 'flex',
                  alignItems: 'left',
                  justifyContent: 'left',
                  borderRadius: '5px',
                }}
              >
                <IconButton
                  onClick={nextImage}
                  sx={{
                    padding: 0,
                    '&:hover': { backgroundColor: 'transparent' },
                  }}
                >
                  <ArrowForwardIosIcon sx={{ color: 'rgba(0, 0, 0, 0.7)' }} />
                </IconButton>
              </Box>
            </Box>
            <CardContent >
              <Box
                onClick={() => navigate(`/card/details/${artist.auth0_sub_id}/${artistProfileSelected}/${countryCode}/${provinceCode}/${city}/${zone}/${musicStyle}/${period}/${date}`)}
                sx={{ cursor: 'pointer' }}
              >

                <Grid container spacing={20}>
                  <Grid item xs={6}>
                    <Chip
                      label={
                        artist.artist_type === 'band' ? 'banda' :
                          artist.artist_type === 'musician' ? 'músico' :
                            artist.artist_type === 'dj' ? 'dj' :
                              artist.artist_type
                      }
                      sx={{
                        backgroundColor: getChipColor(artist.artist_type).bg,
                        color: getChipColor(artist.artist_type).text,
                        fontWeight: 'bold',
                        height: '24px',
                        fontSize: '0.75rem',
                        padding: '0 8px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '120px',
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#2e263de6',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      {artist.rating.toFixed(1)}
                      <StarIcon
                        fontSize="medium"
                        sx={{
                          color: '#FFB400',
                          verticalAlign: 'middle',
                          marginBottom: '5px',
                          ml: 1
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    mt: 1,
                    textAlign: 'left',
                    textJustify: 'inter-word',
                    color: '#2e263de6'
                  }}
                >
                  {artist.artist_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  gutterBottom
                  sx={{
                    textAlign: 'left',
                    textJustify: 'inter-word',
                    mb: 2
                  }}
                >
                  {artist.country}, {artist.province}, {artist.city}, {artist.zone}
                </Typography>
                <Grid container alignItems="center">
                  <Grid item xs={8} sx={{ textAlign: 'left' }}>
                    <Typography
                      variant="body2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#2e263de6',
                        fontWeight: 'bold'
                      }}
                    >
                      R$ {artist.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Impostos inclusos
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        padding: '6px 12px',
                        minWidth: 'auto',
                        backgroundColor: '#4F46E5',
                        '&:hover': {
                          backgroundColor: '#4338CA'
                        },
                        textTransform: 'capitalize',
                      }}
                    >
                      Ver mais
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </>
      )}

    </>


  );
};

export default SearchResultCard;