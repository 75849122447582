import React, { useState, useCallback } from 'react';
import { Box } from "@mui/material";
import { Navigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserProfile from './components/profile/UserProfile';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './components/Footer';
import ArtistList from './components/ArtistList';
import SocialNetworkSection from './components/SocialNetworkSection';
import CardDetails from './components/widget/CardDetails';
import Favorites from './components/profile/Favorites';
import Comments from './components/profile/Comments';
import SearchResults from './components/SearchResults';
import Booking from './components/book/Booking';
import WarningLogin from './components/utils/WarningLogin';

function App() {
  const [userRole, setUserRole] = useState();
  const [isEmailVerified, setIsEmailVerified] = useState();
  const [isProfileCompleted, setIsProfileCompleted] = useState();
  const [userPicture, setUserPicture] = useState();
  const { isAuthenticated, isLoading } = useAuth0();
  const actionArtistList = 'top5';

  const handleUserData = useCallback((userRole, isEmailVerified, isProfileCompleted, userPicture) => {
    setUserRole(userRole);
    setIsEmailVerified(isEmailVerified);
    setIsProfileCompleted(isProfileCompleted);
    setUserPicture(userPicture);
  }, []);


  if (isLoading) {
    return <div>Carregando...</div>;
  }

  return (
    <Router>
      <div className="App">

        <Routes>
          <Route
            path='/'
            element={
              isAuthenticated ? (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <HeroSection
                    isEmailVerified={isEmailVerified}
                    isProfileCompleted={isProfileCompleted}
                  />
                  <ArtistList
                    actionArtistList={actionArtistList}
                  />
                  <SocialNetworkSection />
                  <Footer />

                </>
              ) : (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <HeroSection
                  />
                  <Box sx={{ flex: 1 }}>
                    <ArtistList
                      actionArtistList={actionArtistList}
                    />
                  </Box>
                  <SocialNetworkSection />
                  <Footer />
                </>
              )
            }
          />
          <Route
            path='auth/profile'
            element={
              isAuthenticated ? (
                <>
                  <Navbar
                    handleUserData={handleUserData}
                  />
                  <UserProfile
                    userRole={userRole}
                    userPicture={userPicture}
                    isProfileCompleted={isProfileCompleted}
                  />
                </>
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path='card/details/:id'
            element={
              isAuthenticated ? (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                    userRole={userRole}
                    userPicture={userPicture}
                  />

                </>
              ) : (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                  />
                </>
              )
            }
          />

          <Route
            path='card/details/:id/:artist_profile_selected/:artist_country_code/:artist_province_code/:artist_city/:artist_zone/:artist_music_style/:artist_period/:artist_date'
            element={
              isAuthenticated ? (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                    userRole={userRole}
                    userPicture={userPicture}
                  />

                </>
              ) : (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <CardDetails
                  />
                </>
              )
            }
          />

          <Route
            path='auth/profile/favorites/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Favorites
                    userRole={userRole}
                  />
                </>
              )
            }
          />

          <Route
            path='auth/profile/comments/:id'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Comments />
                </>
              )
            }
          />

          <Route
            path="/searchresults"
            element={
              <>
                <Navbar handleUserData={handleUserData} />
                <SearchResults />
              </>

            }
          />

          <Route
            path='booking/:id'
            element={
              isAuthenticated ? (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <Booking />
                </>
              ) : (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <WarningLogin
                    title="Ops! Você não tem acesso a isso"
                    description="Você precisa estar logado ou criar uma conta contratante para fazer sua reserva. Para isso Inicie Sua Sessão no canto superior direito."
                    highlightedText="Inicie Sua Sessão"
                  />

                </>
              )
            }
          />

          <Route
            path='access_refused'
            element={
              isAuthenticated && (
                <>
                  <Navbar handleUserData={handleUserData} />
                  <WarningLogin
                    title="Ops! Você não tem acesso a isso"
                    description="Você precisa ter uma conta do tipo Contratante para fazer uma reserva."
                    highlightedText="Contratante"
                  />
                </>
              )
            }
          />

        </Routes>



      </div>
    </Router>

  );
}

export default App;
