import React, { useEffect, useState } from "react";
import {
    Grid,
    Typography,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Card,
    CardContent,
    Switch,
    Button,
    useMediaQuery,
    Box,
    CircularProgress
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";

const Booking = () => {
    const location = useLocation();
    const { id } = useParams();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const [artist, setArtist] = useState(location.state?.artist || null);
    const [artistTypeLabel, setArtistTypeLabel] = useState("");
    const [loading, setLoading] = useState(true);

    const apiAuthKey = process.env.REACT_APP_API_AUTH_KEY;
    const railwayAPIURL = process.env.REACT_APP_RAILWAY_API_URL;

    useEffect(() => {
        const fetchUserArtist = async () => {
            try {
                const response = await fetch(
                    `${railwayAPIURL}/api/v1/profile/getuser?auth0_sub_id=${id}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authKey: apiAuthKey,
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch user artist");
                }
                const result = await response.json();
                setArtist(result.user);
            } catch (error) {
                console.error("Error fetching user artist:", error);
            }
        };
    
        const enforceLoadingDelay = setTimeout(() => setLoading(false), 3000);
    
        if (!artist) {
            fetchUserArtist().finally(() => {
                clearTimeout(enforceLoadingDelay);
                setTimeout(() => setLoading(false), 3000);
            });
        } else {
            setTimeout(() => setLoading(false), 3000);
        }
    
        return () => clearTimeout(enforceLoadingDelay);
    }, [artist, railwayAPIURL, apiAuthKey, id]);
    
    

    useEffect(() => {
        if (artist && artist.artist_type) {
            const newArtistTypeLabel = `${artist.artist_type.includes("band")
                ? "a banda "
                : artist.artist_type.includes("musician")
                    ? "o músico "
                    : artist.artist_type.includes("dj") && "o dj "
                }`;
            setArtistTypeLabel(newArtistTypeLabel);
        }
    }, [artist]);

    if (loading) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                <CircularProgress sx={{ color: "#4F46E5" }} />
                <Typography sx={{ mt: 2 }} variant="h6" color="textSecondary">
                    Processando sua reserva...
                </Typography>
            </Box>
        );
    }

    if (!artist) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 30 }}>
                <Typography variant="h6" color="error">
                    Ops! Ocorreu um erro aqui. Tente novamente.
                </Typography>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mt: isMobile ? Math.min(10, 60) : Math.min(10, 30),
                textAlign: isMobile ? "center" : "left",
            }}
        >
            <Grid container spacing={3} padding={3}>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Legal! Você escolheu {artistTypeLabel}
                        <Box component="span" sx={{ color: "#4F46E5", fontWeight: "bold" }}>
                            {artist.artist_name}
                        </Box>. Complete e revise as informações a seguir para finalizar sua reserva.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Quem é titular da reserva?
                            </Typography>
                            <Typography variant="h6">Titular</Typography>
                            <Grid container spacing={2} marginTop={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Nome Completo"
                                        variant="outlined"
                                        placeholder="Nome completo do titular"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Perfil"
                                        variant="outlined"
                                        placeholder="Perfil do titular"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Usuário"
                                        variant="outlined"
                                        placeholder="Nome de usuário do titular"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <InputLabel>Tipo e número de documento</InputLabel>
                                        <Select value="CPF" label="Tipo e número de documento">
                                            <MenuItem value="CPF">CPF</MenuItem>
                                            <MenuItem value="Passaporte">Passaporte</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Detalhe do pagamento
                            </Typography>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={8}>
                                    <Typography>Passaporte Decolar</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Switch />
                                </Grid>
                            </Grid>
                            <Typography>Hospedagem para 2 pessoas: R$ 2.028</Typography>
                            <Typography>Impostos, taxas e encargos: R$ 87</Typography>
                            <Typography color="green">Desconto: -R$ 516</Typography>
                            <Typography variant="h6">TOTAL: R$ 1.599</Typography>
                            <Button variant="contained" fullWidth style={{ marginTop: 10 }}>
                                Confirmar Compra
                            </Button>
                        </CardContent>
                    </Card>

                    <Card style={{ marginTop: 20 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Detalhe da compra
                            </Typography>
                            <Typography>Marante Executive Hotel</Typography>
                            <Typography>Av. Engenheiro Domingos Ferreira, 668 Boa Viagem</Typography>
                            <Typography>Check-in: 19 nov 2024 - 14:00</Typography>
                            <Typography>Check-out: 23 nov 2024 - 12:00</Typography>
                            <Typography>4 noites, 2 adultos</Typography>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Algum pedido especial?</InputLabel>
                                <Select>
                                    <MenuItem value="Nenhum">Nenhum</MenuItem>
                                </Select>
                            </FormControl>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );

};

export default Booking;
